import React from "react";
import Select from "@mui/joy/Select";
import Box from "@mui/joy/Box";
import Sheet from "@mui/joy/Sheet";
import Link from "@mui/joy/Link";
import { ButtonGroup, FormControl, FormLabel, GlobalStyles } from "@mui/joy";
import Option from "@mui/joy/Option";
import Table from "@mui/joy/Table";
import {
  ArrowDropDown as ArrowDropDownIcon,
  Download,
  IosShareRounded,
  LocationOn,
} from "@mui/icons-material";
import Chip from "@mui/joy/Chip";
import { ColorPaletteProp } from "@mui/joy";
import Typography from "@mui/joy/Typography";
import Button from "@mui/joy/Button";
import { Add } from "@mui/icons-material";
import Grid from "@mui/joy/Grid";
import i18next from "../i18config";

import { Termin, sortTermins } from "../type";
import { useTerminsStore } from "../store/terminsStore";
import RowMenu from "./RowMenu";
import { useUserStore } from "../store/userStore";

import { motion } from "framer-motion";
import { LinearProgress } from "@mui/joy";

import {
  initial_grow,
  initial_fadeIn,
  animate_future,
  animate_past,
  exit_shrink,
  exit_fadeOut,
  transition_slow,
  transition_fast,
} from "../utils/animations";
import dayjs from "dayjs";
import IcalExportModal from "./modals/IcalExportModal";
import { URL } from "../globalConstants";

export default function TerminsTable(props: {
  setOpenModal: React.Dispatch<boolean>;
}) {
  const project_id = useTerminsStore((state) => state.project_id);
  const loading = useTerminsStore((state) => state.loading);
  const loggedIn = useUserStore((state) => state.loggedIn);
  const hasAccessToProjects = useUserStore(
    (state) => state.hasAccessToProjects,
  );
  const termins = useTerminsStore((state) => state.termins);

  // displayTermins are changed based on search parameters, it depends on dilterValue and descending
  //const [displayTermins, setDisplayTermins] = React.useState<Termin[]>(termins);
  const [filterValue, setFilterValue] = React.useState<string>("X");
  const [descending, setDescending] = React.useState<boolean>(false);

  // if the appearance of termins should be animated (is false when applying filter)
  const [animate, setAnimate] = React.useState<boolean>(true);

  // State for exporting iCal file
  const [openIcalExportModal, setOpenIcalExportModal] =
    React.useState<boolean>(false);

  const displayTermins = React.useMemo(() => {
    let temp_termins: Termin[] = termins;
    if (filterValue !== "X") {
      temp_termins = termins.filter((t) => t.termin_type === filterValue);
    }
    temp_termins = sortTermins(temp_termins, descending);
    return temp_termins;
  }, [project_id, termins, descending, filterValue]);

  React.useEffect(() => {
    setAnimate(true);
    setFilterValue("X");
  }, [project_id]);

  const [disableActions, setDisableActions] = React.useState<boolean>(true);
  const userId = useUserStore((state) => state.userId);
  const owner = useTerminsStore((state) => state.owner);

  React.useEffect(() => {
    setDisableActions(!hasAccessToProjects.includes(project_id));
  }, [project_id, userId, owner]);

  const renderFilters = () => (
    <React.Fragment>
      <FormControl size="sm">
        <FormLabel>Tip termina</FormLabel>
        <Select
          size="sm"
          placeholder="Filtriraj tip termina"
          value={filterValue}
          sx={{ boxShadow: "sm" }}
          slotProps={{
            button: { sx: { whiteSpace: "nowrap" } },
          }}
          onListboxOpenChange={() => setAnimate(false)}
          onChange={(
            event: React.SyntheticEvent | null,
            newValue: string | null,
          ) => {
            if (newValue !== null) {
              setFilterValue(newValue);
            }
            if (newValue === "X") {
              return;
            }
            let newTermins: Termin[] = termins.filter(
              (termin) => termin.termin_type === newValue,
            );
            //setDisplayTermins(newTermins);
          }}
        >
          <Option value="X">Vsi</Option>
          <Option value="V">Vaja</Option>
          <Option value="N">Nastop</Option>
          <Option value="K">Koncert</Option>
        </Select>
      </FormControl>
    </React.Fragment>
  );

  if (termins.length === 0 && !loading) return <></>;

  return (
    <React.Fragment>
      <GlobalStyles
        styles={(theme) => ({
          ":root": {
            "--Sidebar-width": "220px",
            [theme.breakpoints.up("lg")]: {
              "--Sidebar-width": "240px",
            },
          },
        })}
      />
      <Grid
        container
        sx={{
          //py: 1,
          display: { xs: "none", sm: "flex" },
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Grid
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            display: { xs: "none", sm: "flex" },
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: { xs: "120px", md: "160px" },
            },
          }}
        >
          {renderFilters()}
        </Grid>
        <Grid
          sx={{
            pt: 3,
            display: "flex",
            justifyContent: "right",
          }}
        >
          <ButtonGroup sx={{ boxShadow: "md" }}>
            <Button
              component="a"
              onClick={() => {
                setOpenIcalExportModal(true);
              }}
              color="neutral"
              variant="outlined"
              startDecorator={<IosShareRounded />}
              size="sm"
            >
              Izvozi v koledar
            </Button>
            <Button
              component="a"
              href={
                "https://hudniki.pythonanywhere.com/endpoints/pdf/" + project_id
              }
              color="neutral"
              variant="outlined"
              startDecorator={<Download />}
              size="sm"
            >
              Izvozi urnik (PDF)
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
      <Sheet
        //className="OrderTableContainer"
        variant="solid"
        sx={{
          backgroundColor: "white",
          display: { xs: "none", sm: "initial" },
          width: "100%",
          m: 1.5,
          borderRadius: "md",
          flexShrink: 1,
          overflow: "auto",
          maxHeight: { sm: "50vh", md: "60vh", lg: "70vh" },
        }}
      >
        <Table
          variant="outlined"
          aria-labelledby="tableTitle"
          stickyHeader
          hoverRow
          sx={(theme) => ({
            "--TableCell-headBackground":
              "var(--joy-palette-background-level1)",
            "--Table-headerUnderlineThickness": "1px",
            "--TableRow-hoverBackground":
              "var(--joy-palette-background-level1)",
            "--TableCell-paddingY": "4px",
            "--TableCell-paddingX": "8px",
            boxShadow: theme.shadow.sm,
            transform: "translateY(0px)",
            "--joy-shadowRing": "0 0 #000",
          })}
        >
          <thead>
            <tr>
              <th
                style={{
                  width: 90,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                {i18next.t("Tip termina")}
              </th>
              <th
                style={{
                  width: 180,
                  textAlign: "center",
                  padding: "12px 6px",
                }}
              >
                <Link
                  underline="none"
                  color="primary"
                  component="button"
                  onClick={() => {
                    setDescending(!descending);
                    //setDisplayTermins(
                    //  sortTermins(displayTermins, descending),
                    //);
                  }}
                  fontWeight="lg"
                  endDecorator={<ArrowDropDownIcon />}
                  sx={{
                    "& svg": {
                      transition: "0.2s",
                      transform:
                        descending === true ? "rotate(0deg)" : "rotate(180deg)",
                    },
                  }}
                >
                  {i18next.t("Datum")}
                </Link>
              </th>
              <th style={{ width: 140, padding: "12px 6px" }}>
                {i18next.t("Ura")}
              </th>
              <th style={{ width: 240, padding: "12px 6px" }}>
                {i18next.t("Lokacija")}
              </th>
            </tr>
          </thead>
          {displayTermins.map((termin) => (
            <motion.tr
              style={{ overflow: "hidden" }}
              layout={animate}
              initial={animate ? initial_grow : initial_fadeIn}
              animate={
                dayjs(termin.date, "DD.MM.YYYY", true)
                  .hour(+termin.end.split(":")[0])
                  .minute(+termin.end.split(":")[1]) > dayjs()
                  ? animate_future
                  : animate_past
              }
              exit={animate ? exit_shrink : exit_fadeOut}
              transition={animate ? transition_slow : transition_fast}
              key={`termin_id_${termin.id.toString()}`}
            >
              <td style={{ textAlign: "center", width: 120 }}>
                <Typography fontWeight={600} gutterBottom>
                  {
                    <Chip
                      variant="soft"
                      size="md"
                      color={
                        {
                          V: "warning",
                          N: "primary",
                          K: "success",
                        }[termin.termin_type] as ColorPaletteProp
                      }
                    >
                      {" "}
                      {i18next.t(termin.terminTypeFull.toString())}
                    </Chip>
                  }
                </Typography>
              </td>
              <td style={{ textAlign: "center" }}>
                <Typography level="body-sm" fontWeight="500">
                  {termin.date}
                </Typography>
              </td>
              <td>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <div>
                    <Typography level="body-sm">
                      {termin.start.slice(0, 5)} - {termin.end.slice(0, 5)}
                    </Typography>
                  </div>
                </Box>
              </td>
              <td>
                <Box sx={{ display: "flex", gap: 2, alignItems: "center" }}>
                  <Link
                    color="neutral"
                    variant="soft"
                    startDecorator=<LocationOn />
                    underline="none"
                    href={`https://maps.google.com/?q=${termin.location.replaceAll(" ", "+")}`}
                    target="_blank"
                    level="body-md"
                    sx={{ borderRadius: "lg" }}
                  >
                    {termin.location.split(",")[0]}
                  </Link>
                  <RowMenu
                    termin={termin}
                    setOpenModal={props.setOpenModal}
                    disableActions={disableActions}
                  />
                </Box>
              </td>
            </motion.tr>
          ))}
        </Table>

        {loading ? (
          <motion.div
            initial={{ opacity: 0.5 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{
              delay: 0.2,
              duration: 1,
            }}
          >
            <LinearProgress
              sx={{
                "--LinearProgress-thickness": "3px",
              }}
            />
          </motion.div>
        ) : (
          <Box sx={{ height: "3px" }}></Box>
        )}
      </Sheet>

      <Grid
        container
        sx={{
          //py: 1,
          display: { xs: "none", sm: "flex" },
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-between",
        }}
      >
        <Grid
          className="SearchAndFilters-tabletUp"
          sx={{
            borderRadius: "sm",
            display: { xs: "none", sm: "flex" },
            flexDirection: "row",
            flexWrap: "wrap",
            gap: 1.5,
            "& > *": {
              minWidth: { xs: "120px", md: "160px" },
            },
          }}
        >
          <Button
            disabled={disableActions}
            color="neutral"
            startDecorator={<Add />}
            size="md"
            onClick={() => {
              props.setOpenModal(true);
            }}
            sx={{ boxShadow: "sm" }}
          >
            Dodaj termin
          </Button>
        </Grid>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "right",
          }}
        >
          <Button
            color="neutral"
            startDecorator={<Download />}
            size="sm"
            component="a"
            href={`${URL}/all_concert_programs/${project_id}`}
          >
            Programski listi
          </Button>
        </Grid>
      </Grid>
      <IcalExportModal
        open={openIcalExportModal}
        setOpen={setOpenIcalExportModal}
        project_id={project_id}
      />
    </React.Fragment>
  );
}
