import { Dropdown } from "@mui/joy";
import Menu from "@mui/joy/Menu";
import MenuItem from "@mui/joy/MenuItem";
import MenuButton from "@mui/joy/MenuButton";
import Divider from "@mui/joy/Divider";
import { MoreHorizRounded } from "@mui/icons-material";
import IconButton from "@mui/joy/IconButton";

import { Termin } from "../type";
import { useUserStore } from "../store/userStore";
import { useIndividualTerminStore } from "../store/terminsStore";
import { URL } from "../globalConstants";

function collapseOut(termin_id: string) {
  const element = document.getElementById(termin_id);
  element?.classList.remove("collapseIn");
  element?.classList.add("collapseOut");
}

export default function RowMenu(props: {
  termin: Termin;
  setOpenModal: React.Dispatch<boolean>;
  disableActions?: boolean;
}) {
  const loggedIn = useUserStore((state) => state.loggedIn);
  const setIndividualTermin = useIndividualTerminStore(
    (state) => state.setTermin,
  );
  const setEditing = useIndividualTerminStore((state) => state.setEditing);
  const deleteTermin = useIndividualTerminStore((state) => state.deleteTermin);

  return (
    <>
      <Dropdown>
        <MenuButton
          onFocus={() => setIndividualTermin(props.termin)}
          slots={{ root: IconButton }}
          slotProps={{
            root: { variant: "plain", color: "neutral", size: "sm" },
          }}
        >
          <MoreHorizRounded />
        </MenuButton>
        <Menu size="sm" sx={{ minWidth: 140 }}>
          {props.termin.termin_type !== "V" ? (
            <MenuItem
              component="a"
              href={`${URL}/concert_program/${props.termin.id}`}
            >
              Program (PDF)
            </MenuItem>
          ) : (
            <></>
          )}
          <MenuItem
            disabled={!loggedIn || props.disableActions}
            onClick={() => {
              setEditing(true);
              props.setOpenModal(true);
            }}
          >
            Edit
          </MenuItem>
          <Divider />
          <MenuItem
            color="danger"
            disabled={!loggedIn || props.disableActions}
            onClick={() => {
              deleteTermin();
              collapseOut(props.termin.id.toString());
            }}
          >
            Delete
          </MenuItem>
        </Menu>
      </Dropdown>
    </>
  );
}
