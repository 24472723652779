import { momentLocalizer, Views, View, Calendar } from "react-big-calendar";
import moment from "moment";
import { useEffect, useState } from "react";
import { Termin } from "../type";
import { useTerminsStore } from "../store/terminsStore";
import axios from "axios";
import { useProjectsStore } from "../store/projectsStore";
import { useCalendarStore } from "../store/calendarStore";
import React from "react";
import dayjs from "dayjs";

const localizer = momentLocalizer(moment);

function formEventsArray(termins: Termin[]) {
  const events = termins.map((termin) => {
    const start_date =
      termin.date.split(".")[2] +
      "-" +
      termin.date.split(".")[1] +
      "-" +
      termin.date.split(".")[0] +
      "T" +
      termin.start;
    const end_date =
      termin.date.split(".")[2] +
      "-" +
      termin.date.split(".")[1] +
      "-" +
      termin.date.split(".")[0] +
      "T" +
      termin.end;
    return {
      id: termin.id,
      ref: termin.id.toString(),
      title: termin.location.split(",")[0],
      start: moment(start_date).toDate(),
      end: moment(end_date).toDate(),
    };
  });
  return events;
}

export default function MyCalendar() {
  const [view, setView] = useState(Views.WEEK);
  const [date, setDate] = useState(new Date());
  const [events, setEvents] = useState<
    { id: number; title: string; start: Date; end: Date }[]
  >([]);

  const project_id = useTerminsStore((state) => state.project_id);
  const termins = useTerminsStore((state) => state.termins);
  const fetchTerminsFromServer = useTerminsStore((state) => state.fetchTermins);
  const fetchTermins = useCalendarStore((state) => state.fetchTermins);
  const setCalendarView = useCalendarStore((state) => state.setCalendarView);

  React.useEffect(() => {
    fetchTerminsFromServer();
    //fetchTermins()
  }, [project_id]);

  React.useEffect(() => {
    fetchTermins();
    setEvents(formEventsArray(termins));
  }, [termins]);

  useEffect(() => {
    if (termins[0] !== undefined) {
      setDate(dayjs(termins[0].date, "DD.MM.YYYY").toDate());
    }
  }, [termins, events, project_id]);

  function handleDoubleClick(calEvent: {
    id: number;
    title: string;
    start: Date;
    end: Date;
  }) {
    setCalendarView(false);
  }

  return (
    <div style={{ height: "80vh" }}>
      <Calendar
        min={new Date(2017, 10, 0, 8, 0, 0)}
        max={new Date(2017, 10, 0, 23, 59, 59)}
        localizer={localizer}
        defaultView={Views.WEEK}
        //view={view}
        date={date}
        //onView={(view) => setView(view)}
        onNavigate={(date) => setDate(date)}
        events={events}
        // onDoubleClickEvent={(calEvent) => handleDoubleClick(calEvent)}
        startAccessor="start"
        endAccessor="end"
      />
    </div>
  );
}
